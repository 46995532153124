.App {
  text-align: center;
 
 
}

@import url('https://fonts.googleapis.com/css2?family=Bonheur+Royale&display=swap');
 
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.youTube{
  border-radius: 1.5rem;
  
}

@media (max-width: 600px){
  .youTube{
    width: auto;
    height: auto;
  }

  
}

#logout-button{
  position:absolute;
top: 0;
right: 0
 
}